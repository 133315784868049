import './styles/app.css';
import React from 'react';
import Main from './components/Main';
import Header from './components/Header';

function App() {
  return (
    <div className="App">
      <Header />
      <Main />
    </div>
  )
  /*const [targetLevel, setTargetLevel] = useState(1);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [showRecipes, setShowRecipes] = useState(false);
  const [playerExpGained, setPlayerExpGained] = useState(0);
  const [remainingExperience, setRemainingExperience] = useState(0);
  const [currentSkill, setCurrentSkill] = useState(skillData.arcana.id);
  
  const calculateItemsNeeded = (itemExperience) => {
    return Math.ceil(remainingExperience/itemExperience);
  }

  const getViableCraftingLevels = (level) => {
    if (currentLevel >= level) { return '' };

    return currentLevel <= level && targetLevel >= level ? 'inside-levels' : 'outside-levels';
  }

  return (
    <div className="App">
      <header>
        <div>
          <img src={logo} width="80" height="80" alt="Alcalchemy logo"/>
          <h1>AL<span className="accent">CALC</span>HEMY 
          <span className="sub-title">New World Calculators</span></h1>
        </div>
        
      </header>
      <main>
        <Calculator { 
          ...{ 
            targetLevel, 
            currentSkill, 
            currentLevel, 
            setShowRecipes,
            setTargetLevel, 
            setCurrentLevel,
            playerExpGained,
            setCurrentSkill,
            setPlayerExpGained,
            remainingExperience, 
            setRemainingExperience
          }
        }/>
        <section className="recipes-wrapper">
          <RecipeCard 
            classes="header"
            recipeName="Recipe"
            recipeLevel="Skill level"
            recipeExperience="Experience"
            recipeCategory="Category"
            recipesNeeded = "Items needed"
          />
          {showRecipes &&
            skillData[currentSkill].dataSet.map((recipe, index) => {
                return (
                    <RecipeCard 
                      key={ index } 
                      classes={ getViableCraftingLevels(recipe.level) }
                      recipeName={ recipe.name }
                      recipeLevel={ recipe.level }
                      recipeExperience={ recipe.xp }
                      recipeCategory={ recipe.category }
                      recipesNeeded ={ calculateItemsNeeded(recipe.xp) }
                    />
                )
            })
          }
      </section>
      </main>
      
    </div>
  );*/
}

export default App;
