import '../styles/app.css';
import React, { useState } from 'react';
import { skillData } from '../data/enums';
import Calculator from '../components/Calculator';
import RecipeCard from '../components/RecipeCard';

function Calculators() {
    const [targetLevel, setTargetLevel] = useState(1);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [showRecipes, setShowRecipes] = useState(false);
  const [playerExpGained, setPlayerExpGained] = useState(0);
  const [remainingExperience, setRemainingExperience] = useState(0);
  const [currentSkill, setCurrentSkill] = useState(skillData.arcana.id);
  
  const calculateItemsNeeded = (itemExperience) => {
    return Math.ceil(remainingExperience/itemExperience);
  }

  const getViableCraftingLevels = (level) => {
    if (currentLevel >= level) { return '' };

    return currentLevel <= level && targetLevel >= level ? 'inside-levels' : 'outside-levels';
  }

  return (
    <div>
      <main>
        <Calculator { 
          ...{ 
            targetLevel, 
            currentSkill, 
            currentLevel, 
            setShowRecipes,
            setTargetLevel, 
            setCurrentLevel,
            playerExpGained,
            setCurrentSkill,
            setPlayerExpGained,
            remainingExperience, 
            setRemainingExperience
          }
        }/>
        <section className="recipes-wrapper">
          <RecipeCard 
            key="header"
            classes="header"
            recipeName="Recipe"
            recipeLevel="Skill level"
            recipeExperience="Experience"
            recipeCategory="Category"
            recipesNeeded = "Items needed"
          />
          {showRecipes &&
            skillData[currentSkill].dataSet.map((recipe, index) => {
                return (
                    <RecipeCard 
                      recipeName={ recipe.name }
                      recipeLevel={ recipe.level }
                      recipeExperience={ recipe.xp }
                      key={ index + '-' + recipe.name } 
                      recipeCategory={ recipe.category }
                      classes={ getViableCraftingLevels(recipe.level) }
                      recipesNeeded ={ calculateItemsNeeded(recipe.xp) }
                    />
                )
            })
          }
      </section>
      </main>
      
    </div>
  );
}

export default Calculators;
