import '../styles/recipes.css'
import arcanaIcon from '../assets/arcana-icon.svg';
import React from 'react';

const RecipeCard = ({ recipeName, classes, recipeLevel, recipesNeeded, recipeExperience, recipeCategory }) => {

    //Returns canvas
    return (
        <React.Fragment>
            <div className={"recipe-card " + classes}>
                <div className="recipe-info">
                    <img src={arcanaIcon} alt={ recipeCategory + " icon" } width="12" height="12" />
                    <p title={ recipeName }>{ recipeName } </p>
                    <p title={ recipeCategory }>{ recipeCategory } </p>
                    <p>{ recipeLevel } </p>
                    <p >{ recipeExperience } </p>
                    <p>{ recipesNeeded }</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RecipeCard;