import '../styles/calculator.css';
import React, { useEffect } from 'react';
import { skillData } from '../data/enums';

const Calculator = ({
    targetLevel, 
    currentSkill, 
    currentLevel, 
    setShowRecipes,
    setTargetLevel, 
    setCurrentLevel,
    setCurrentSkill,
    remainingExperience, 
    setRemainingExperience}) => {

    useEffect(() => {
        const updateCalculations = () => {
            if ((targetLevel <= currentLevel)) { 
                setShowRecipes(false);
                return false;
            } else {
                setShowRecipes(true);
            }
            
            if (skillData[currentSkill].type === 'crafting') {

            }
            const expChart = skillData[currentSkill].expChart;
            const experienceArray = expChart.slice(currentLevel, targetLevel);
            const totalExperience = experienceArray.reduce((a, b) => a + b);
            setRemainingExperience(totalExperience);
        }

        updateCalculations();
    }, [targetLevel, currentLevel, currentSkill]);

    const fixInputValue = (value) => {
        let newValue = value.replace(/[^0-9]/g, '');
        if (newValue > 200) { newValue = 200 };
        return +newValue;
    }
    
    //Returns canvas
    return (
        <React.Fragment>
            <section className="calculator">
                <div className="calculator-wrapper">
                    <div className="skill-select">
                        <h2 className="skill-title">{ skillData[currentSkill].name }</h2>
                        <div className="custom-dropdown"> 
                            {Object.keys(skillData).map((key) => {
                                return (
                                    <button 
                                    key={key}
                                    className="option"
                                    title={ skillData[key].name }
                                    onClick={ () => { setCurrentSkill(skillData[key].id) }}>{ skillData[key].name }</button>
                                )
                            })}
                        </div>
                    </div>
                    <label htmlFor="current-level">Current Level:</label>
                    <input onChange={(e) => { setCurrentLevel(fixInputValue(e.target.value))  }} value={currentLevel} name="current-level" type="text" title="Enter current level" className="current-level" />

                    <label htmlFor="target-level">Target Level:</label>
                    <input onChange={(e) => { setTargetLevel(fixInputValue(e.target.value))  }} value={targetLevel} name="target-level" type="text" title="Enter target level" className="target-level" />

                    <p>Experience remaining: <span className="experience-to-go">{remainingExperience}</span></p>
                </div>
                
            </section>
        </React.Fragment>
    )
}

export default Calculator;