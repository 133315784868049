import { 
  craftExpChart, 
  refineExpChart, 
  craftPlayerExpChart, 
  craftExpChartCooking, 
  refinePlayerExpChart,
  craftExpChartFurnishing, 
  craftPlayerExpChartJewel, 
  craftPlayerExpChartFurnishing 
} from '../data/experience';
import arcanaRecipes from './recipes/arcanaRecipes.json';
import weavingRecipes from './recipes/weavingRecipes.json';
import cookingRecipes from './recipes/cookingRecipes.json';
import armoringRecipes from './recipes/armoringRecipes.json';
import smeltingRecipes from './recipes/smeltingRecipes.json';
import furnishingRecipes from './recipes/furnishingRecipes.json';
import engineeringRecipes from './recipes/engineeringRecipes.json';
import woodworkingRecipes from './recipes/woodworkingRecipes.json';
import stonecuttingRecipes from './recipes/stonecuttingRecipes.json';
import jewelcraftingRecipes from './recipes/jewelcraftingRecipes.json';
import leatherworkingRecipes from './recipes/leatherworkingRecipes.json';
import weaponsmithingRecipes from './recipes/weaponsmithingRecipes.json';


export const skillData = {
  arcana: {
    id: 'arcana',
    name: 'Arcana',
    type: 'Crafting',
    dataSet: arcanaRecipes,
    expChart: craftExpChart,
    playerExpChart: craftPlayerExpChart 
  },
  armoring: {
    id: 'armoring',
    name: 'Armoring',
    type: 'Crafting',
    dataSet: armoringRecipes,
    expChart: craftExpChart,
    playerExpChart: craftPlayerExpChart
  },
  cooking: {
    id: 'cooking',
    name: 'Cooking',
    type: 'Crafting',
    dataSet: cookingRecipes,
    expChart: craftExpChartCooking,
    playerExpChart: craftPlayerExpChart
  },
  engineering: {
    id: 'engineering',
    name: 'Engineering',
    type: 'Crafting',
    dataSet: engineeringRecipes,
    expChart: craftExpChart,
    playerExpChart: craftPlayerExpChart
  },
  furnishing: {
    id: 'furnishing',
    name: 'Furnishing',
    type: 'Crafting',
    dataSet: furnishingRecipes,
    expChart: craftExpChartFurnishing,
    playerExpChart: craftPlayerExpChartFurnishing
  },
  jewelcrafting: {
    id: 'jewelcrafting',
    name: 'Jewel Crafting',
    type: 'Crafting',
    dataSet: jewelcraftingRecipes,
    expChart: craftExpChart,
    playerExpChart: craftPlayerExpChartJewel
  },
  weaponsmithing: {
    id: 'weaponsmithing',
    name: 'Weapon Smithing',
    type: 'Crafting',
    dataSet: weaponsmithingRecipes,
    expChart: craftExpChart,
    playerExpChart: craftPlayerExpChart
  },
  smelting: {
    id: 'smelting',
    name: 'Smelting',
    type: 'Refining',
    dataSet: smeltingRecipes,
    expChart: refineExpChart,
    playerExpChart: refinePlayerExpChart
  },
  woodworking: {
    id: 'woodworking',
    name: 'Woodworking',
    type: 'Refining',
    dataSet: woodworkingRecipes,
    expChart: refineExpChart,
    playerExpChart: refinePlayerExpChart
  },
  leatherworking: {
    id: 'leatherworking',
    name: 'Leatherworking',
    type: 'Refining',
    dataSet: leatherworkingRecipes,
    expChart: refineExpChart,
    playerExpChart: refinePlayerExpChart
  },
  weaving: {
    id: 'weaving',
    name: 'Weaving',
    type: 'Refining',
    dataSet: weavingRecipes,
    expChart: refineExpChart,
    playerExpChart: refinePlayerExpChart
  },
  stonecutting: {
    id: 'stonecutting',
    name: 'Stonecutting',
    type: 'Refining',
    dataSet: stonecuttingRecipes,
    expChart: refineExpChart,
    playerExpChart: refinePlayerExpChart
  }
}