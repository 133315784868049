import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Updates from '../pages/Updates';
import Calculators from '../pages/Calculators';

const Main = () => {
  return (
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' component={Calculators}></Route>
      <Route exact path='/updates' component={Updates}></Route>
    </Switch>
  );
}

export default Main