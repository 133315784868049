//Crafting Experience Charts

export const craftExpChart = [100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180, 185, 190, 195, 200, 205, 210, 215, 220, 225, 230, 235, 240, 245, 250, 255, 260, 265, 270, 275, 280, 285, 290, 295, 300, 305, 310, 315, 320, 325, 330, 335, 340, 345, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1050, 1100, 1150, 1200, 1250, 1300, 1350, 1400, 1450, 1500, 1550, 1600, 1650, 1700, 1750, 1800, 1850, 1900, 1950, 2000, 2050, 2100, 2150, 2200, 2250, 2300, 2350, 2400, 2450, 2500, 2550, 2600, 2650, 2700, 2750, 2800, 2850, 2900, 2950, 3534, 3720, 3906, 4092, 4278, 4464, 4650, 4836, 5022, 5208, 5394, 5580, 5766, 5952, 6138, 6324, 6510, 6696, 6882, 7068, 7254, 7440, 7626, 7812, 7998, 8184, 8370, 8556, 8742, 8928, 9114, 9300, 9486, 9672, 9858, 10044, 10230, 10416, 10602, 10788, 10974, 11160, 11346, 11532, 11718, 11904, 12090, 12276, 12462, 12648, 12400, 13020, 13640, 14260, 14880, 15500, 16120, 16740, 17360, 17980, 18600, 19220, 19840, 20460, 21080, 21700, 22320, 22940, 23560, 24180, 24800, 25420, 26040, 26660, 27280, 27900, 28520, 29140, 29760, 30380, 31000, 31620, 32240, 32860, 33480, 34100, 34720, 35340, 35960, 36580, 37200, 37820, 38440, 39060, 39680, 40300, 40920, 41540, 42160, 42780];

export const craftExpChartCooking = [3, 39, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80, 82, 84, 86, 88, 90, 92, 94, 96, 98, 100, 102, 104, 106, 108, 110, 112, 114, 116, 118, 144, 158, 172, 187, 201, 216, 230, 244, 259, 273, 288, 302, 316, 331, 345, 360, 374, 388, 403, 417, 432, 446, 460, 475, 489, 504, 518, 532, 547, 561, 576, 590, 604, 619, 633, 648, 662, 676, 691, 705, 720, 734, 748, 763, 777, 792, 806, 820, 835, 849, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320, 1380, 1440, 1500, 1560, 1620, 1680, 1740, 1800, 1860, 1920, 1980, 2040, 2100, 2160, 2220, 2280, 2340, 2400, 2460, 2520, 2580, 2640, 2700, 2760, 2820, 2880, 2940, 3000, 3060, 3120, 3180, 3240, 3300, 3360, 3420, 3480, 3540, 3600, 3660, 3720, 3780, 3840, 4200, 4350, 4500, 4650, 4800, 4950, 5100, 5250, 5400, 5550, 5700, 5850, 6000, 6150, 6300, 6450, 6600, 6750, 6900, 7050, 7200, 7350, 7500, 7650, 7800, 7950, 8100, 8250, 8400, 8550, 8700, 8850, 9000, 9150, 9300, 9450, 9600, 9750, 9900, 10050, 10200, 10350, 10500, 10650, 10800, 10950, 11100, 11250, 11400, 11550];

export const craftExpChartFurnishing = [100, 115, 130, 145, 160, 175, 190, 205, 220, 235, 250, 265, 280, 295, 310, 325, 340, 355, 370, 385, 400, 415, 430, 445, 460, 475, 490, 505, 520, 535, 550, 565, 580, 595, 610, 625, 640, 655, 670, 685, 700, 715, 730, 745, 760, 775, 790, 805, 820, 835, 1200, 1280, 1360, 1440, 1520, 1600, 1680, 1760, 1840, 1920, 2000, 2080, 2160, 2240, 2320, 2400, 2480, 2560, 2640, 2720, 2800, 2880, 2960, 3040, 3120, 3200, 3280, 3360, 3440, 3520, 3600, 3680, 3760, 3840, 3920, 4000, 4080, 4160, 4240, 4320, 4400, 4480, 4560, 4640, 4720, 4800, 4880, 4960, 5040, 5120, 9600, 10080, 10560, 11040, 11520, 12000, 12480, 12960, 13440, 13920, 14400, 14880, 15360, 15840, 16320, 16800, 17280, 17760, 18240, 18720, 19200, 19680, 20160, 20640, 21120, 21600, 22080, 22560, 23040, 23520, 24000, 24480, 24960, 25440, 25920, 26400, 26880, 27360, 27840, 28320, 28800, 29280, 29760, 30240, 30720, 31200, 31680, 32160, 32640, 33120, 70400, 72960, 75520, 78080, 80640, 83200, 85760, 88320, 90880, 93440, 96000, 98560, 101120, 103680, 106240, 108800, 111360, 113920, 116480, 119040, 121600, 124160, 126720, 129280, 131840, 134400, 136960, 139520, 142080, 144640, 147200, 149760, 152320, 154880, 157440, 160000, 162560, 165120, 167680, 170240, 172800, 175360, 177920, 180480, 183040, 185600, 188160, 190720, 193280, 195840];

//Crafting Player Experience Charts

export const craftPlayerExpChart = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74, 77, 80, 83, 86, 89, 92, 95, 98, 101, 104, 108, 112, 116, 120, 124, 128, 132, 136, 140, 144, 148, 152, 156, 160, 164, 168, 172, 176, 180, 184, 188, 192, 196, 200, 204, 209, 214, 219, 224, 229, 234, 239, 244, 249, 254, 259, 264, 269, 274, 279, 284, 289, 294, 299, 304, 309, 314, 319, 324, 329, 335, 341, 347, 353, 359, 365, 371, 377, 383, 389, 395, 401, 407, 413, 419, 425, 431, 437, 443, 449, 455, 461, 467, 473, 479, 486, 493, 500, 507, 514, 521, 528, 535, 542, 549, 556, 563, 570, 577, 584, 591, 598, 605, 612, 619, 626, 633, 640, 647, 654, 661, 668, 675, 682, 689, 696, 703, 710, 717, 724, 732, 740, 748, 756, 764, 772, 780, 788, 796, 804, 812, 820, 828, 836, 844, 853, 862, 871, 880, 889, 898, 907, 916, 925, 934, 943, 952, 961, 970, 979, 989, 999, 1009, 1019, 1029, 1039, 1049, 1059, 1069, 1079];

export const craftPlayerExpChartJewel = [15, 17, 18, 20, 21, 23, 24, 26, 27, 29, 30, 32, 33, 35, 36, 39, 42, 45, 48, 51, 54, 57, 60, 63, 66, 69, 72, 75, 78, 81, 84, 87, 90, 93, 96, 99, 102, 105, 108, 111, 116, 120, 125, 129, 134, 138, 143, 147, 152, 156, 162, 168, 174, 180, 186, 192, 198, 204, 210, 216, 222, 228, 234, 240, 246, 252, 258, 264, 270, 276, 282, 288, 294, 300, 306, 314, 321, 329, 336, 344, 351, 359, 366, 374, 381, 389, 396, 404, 411, 419, 426, 434, 441, 449, 456, 464, 471, 479, 486, 494, 503, 512, 521, 530, 539, 548, 557, 566, 575, 584, 593, 602, 611, 620, 629, 638, 647, 656, 665, 674, 683, 692, 701, 710, 719, 729, 740, 750, 761, 771, 782, 792, 803, 813, 824, 834, 845, 855, 866, 876, 887, 897, 908, 918, 929, 939, 950, 960, 971, 981, 992, 1002, 1013, 1023, 1034, 1044, 1055, 1065, 1076, 1086, 1098, 1110, 1122, 1134, 1146, 1158, 1170, 1182, 1194, 1206, 1218, 1230, 1242, 1254, 1266, 1280, 1293, 1307, 1320, 1334, 1347, 1361, 1374, 1388, 1401, 1415, 1428, 1442, 1455, 1469, 1484, 1499, 1514, 1529, 1544, 1559, 1574, 1589, 1604, 1619];

export const craftPlayerExpChartFurnishing = [20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 52, 56, 60, 64, 68, 72, 76, 80, 84, 88, 92, 96, 100, 104, 108, 112, 116, 120, 124, 128, 132, 136, 140, 144, 148, 154, 160, 166, 172, 178, 184, 190, 196, 202, 208, 216, 224, 232, 240, 248, 256, 264, 272, 280, 288, 296, 304, 312, 320, 328, 336, 344, 352, 360, 368, 376, 384, 392, 400, 408, 418, 428, 438, 448, 458, 468, 478, 488, 498, 508, 518, 528, 538, 548, 558, 568, 578, 588, 598, 608, 618, 628, 638, 648, 658, 670, 682, 694, 706, 718, 730, 742, 754, 766, 778, 790, 802, 814, 826, 838, 850, 862, 874, 886, 898, 910, 922, 934, 946, 958, 972, 986, 1000, 1014, 1028, 1042, 1056, 1070, 1084, 1098, 1112, 1126, 1140, 1154, 1168, 1182, 1196, 1210, 1224, 1238, 1252, 1266, 1280, 1294, 1308, 1322, 1336, 1350, 1364, 1378, 1392, 1406, 1420, 1434, 1448, 1464, 1480, 1496, 1512, 1528, 1544, 1560, 1576, 1592, 1608, 1624, 1640, 1656, 1672, 1688, 1706, 1724, 1742, 1760, 1778, 1796, 1814, 1832, 1850, 1868, 1886, 1904, 1922, 1940, 1958, 1978, 1998, 2018, 2038, 2058, 2078, 2098, 2118, 2138, 2158];

//Refining Experience Charts

export const refineExpChart = [120, 126, 133, 139, 146, 153, 159, 166, 172, 179, 186, 192, 199, 205, 212, 219, 225, 232, 238, 245, 252, 258, 265, 271, 278, 285, 291, 298, 304, 311, 318, 324, 331, 337, 344, 351, 357, 364, 370, 377, 384, 390, 397, 403, 410, 417, 423, 430, 436, 443, 2250, 2283, 2316, 2349, 2382, 2415, 2448, 2481, 2514, 2547, 2580, 2613, 2646, 2679, 2712, 2745, 2778, 2811, 2844, 2877, 2910, 2943, 2976, 3009, 3042, 3075, 3108, 3141, 3174, 3207, 3240, 3273, 3306, 3339, 3372, 3405, 3438, 3471, 3504, 3537, 3570, 3603, 3636, 3669, 3702, 3735, 3768, 3801, 3834, 3867, 20250, 20392, 20535, 20677, 20820, 20962, 21105, 21247, 21390, 21532, 21675, 21817, 21960, 22102, 22245, 22387, 22530, 22672, 22815, 22957, 23100, 23242, 23385, 23527, 23670, 23812, 23955, 24097, 24240, 24382, 24525, 24667, 24810, 24952, 25095, 25237, 25380, 25522, 25665, 25807, 25950, 26092, 26235, 26377, 26520, 26662, 26805, 26947, 27090, 27232, 150000, 151875, 153750, 155625, 157500, 159375, 161250, 163125, 165000, 166875, 168750, 170625, 172500, 174375, 176250, 178125, 180000, 181875, 183750, 185625, 187500, 189375, 191250, 193125, 195000, 196875, 198750, 200625, 202500, 204375, 206250, 208125, 210000, 211875, 213750, 215625, 217500, 219375, 221250, 223125, 225000, 226875, 228750, 230625, 232500, 234375, 236250, 238125, 240000, 241875];

//Refining Player Experience Charts

export const refinePlayerExpChart = [3, 4, 4, 4, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 8, 8, 9, 9, 10, 11, 11, 12, 12, 13, 14, 14, 15, 15, 16, 17, 17, 18, 18, 19, 20, 20, 21, 21, 22, 23, 24, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 38, 39, 40, 41, 42, 44, 45, 46, 47, 48, 50, 51, 52, 53, 54, 56, 57, 58, 59, 60, 62, 63, 65, 66, 68, 69, 71, 72, 74, 75, 77, 78, 80, 81, 83, 84, 86, 87, 89, 90, 92, 93, 95, 96, 98, 99, 101, 103, 105, 106, 108, 110, 112, 114, 115, 117, 119, 121, 123, 124, 126, 128, 130, 132, 133, 135, 137, 139, 141, 142, 144, 146, 148, 150, 153, 155, 157, 159, 161, 163, 165, 167, 169, 171, 174, 176, 178, 180, 182, 184, 186, 188, 190, 192, 195, 197, 199, 201, 203, 205, 207, 209, 211, 213, 216, 218, 220, 222, 225, 227, 230, 232, 234, 237, 239, 242, 244, 246, 249, 251, 254, 256, 259, 262, 264, 267, 270, 273, 275, 278, 281, 283, 286, 289, 291, 294, 297, 300, 303, 306, 309, 312, 315, 318, 321, 324];
