import React from 'react';
import logo from '../assets/logo.svg'
import { Link } from "react-router-dom";



const Header = () => {
  return (
    <header>
        <Link to="/" className="logo">
            <img src={logo} width="80" height="80" alt="Alcalchemy logo"/>
        
            <h1>AL<span className="accent">CALC</span>HEMY 
            <span className="sub-title">New World Calculators</span></h1>
        </Link>
        <Link to="/updates" className="navigation-item">Updates</Link>
    </header>
  );
}

export default Header