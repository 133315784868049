import '../styles/updates.css';
import React, { useState } from 'react';
import UpdateCard from '../components/UpdateCard';

function Updates() {
  return (
    <main>
        <UpdateCard />
    </main>
  );
}

export default Updates;
