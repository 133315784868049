import React from 'react';

const UpdateCard = () => {

    //Returns canvas
    return (
        <React.Fragment>
            
            <div className="updates">
                <p>OCTOBER 19, 2021</p>
                <div className="update-card added">
                    <p>Added refining skills</p>
                </div>
                <div className="update-card fixed">
                    <p>Fixed Jewelcrafting experience chart</p>
                </div>
                <div className="update-card fixed">
                    <p>Fixed Furnishing experience chart</p>
                </div>
                <div className="update-card fixed">
                    <p>Fixed Cooking experience chart</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UpdateCard;